import React, { useState } from 'react'
import styled from 'styled-components'
import MainGrid from '../styling/MainGrid'
import theme from '../styling/theme'
import Button from '../shared/Button/Button'

interface IImageAndText {
  tag?: string
  heading?: string
  img?: string
  imgAlt?: string
}
const ImageAndTextContactUs = ({
  img,
  imgAlt,
  tag,
  heading,
}: IImageAndText) => {
  return (
    <MainGrid>
      <Container>
        <ImageContainer>
          <Img src={img} imgAlt={imgAlt} />
        </ImageContainer>
        <Text>
          {tag && <p className="section-tag">{tag}</p>}
          {heading && <h2>{heading}</h2>}
          <p>
            We are keen to hear about your R&D experience. The first step is to
            get in touch to arrange a chat with one of our advisors. Let us know
            your availability for a call or a teleconference and we will follow
            up. We can be contacted on:{' '}
            <LinkStyled href="mailto:info@rda.co.uk">
              info@rda.co.uk
            </LinkStyled>
          </p>
          <TelNo href="tel:033 33 444 026">033 33 444 026</TelNo>

          <ButtonContainerTelNo>
            <Button href="tel:033 33 444 026">033 33 444 026</Button>
          </ButtonContainerTelNo>
          <Line />
          <Title className="p-large">Opening times</Title>
          <OpeningTimes>
            <Col>
              <p className="p-large">Monday - Friday</p>
            </Col>
            <Col>
              <p className="p-large">9:00am - 5:00pm</p>
            </Col>
          </OpeningTimes>
        </Text>
      </Container>
    </MainGrid>
  )
}

export default ImageAndTextContactUs

const Container = styled.section`
  display: flex;
  flex-direction: column-reverse;
  padding: 50px 0 25px;
  @media (min-width: 1024px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 8rem 0 5rem;
  }
`

const Text = styled.div`
  width: 100%;

  @media (min-width: 1024px) {
    width: 48%;
  }

  & .section-tag {
    margin-bottom: 8px;
  }
  & h2 {
    margin-bottom: 14px;
  }
`
const LinkStyled = styled.a`
  &,
  &:link,
  &:visited {
    color: ${theme.brand.blue};
  }

  &:hover,
  &:active {
    text-decoration: underline;
  }
`

const ImageContainer = styled.div`
  width: 50%;
  max-width: 600px;
  margin-top: 20px;
  @media (min-width: 1024px) {
    width: 45%;
    margin: 0;
  }
`
const Img = styled.img`
  width: 100%;
`
const TelNo = styled.a`
  text-decoration: none;
  margin-top: 5px;
  color: ${theme.text.main} !important;
  font-size: 4rem;
  font-weight: 700;
  letter-spacing: -0.43px;
  line-height: 5rem;
  display: none;
  @media (min-width: 1024px) {
    display: block;
    margin: 16px 0 10px;
  }
`
const ButtonContainer = styled.div`
  width: 100%;
  margin-top: 12px;
  max-width: 450px;
  margin: 12px 0 0;
  display: flex;
  @media (min-width: 1024px) {
    max-width: 252px;
    margin: 21px 0 0;
    justify-content: flex-start;
  }
`

const ButtonContainerTelNo = styled(ButtonContainer)`
  @media (min-width: 1024px) {
    display: none;
  }
`
const Line = styled.div`
  display: none;
  height: 2px;
  width: 167px;
  background: #9d9d9c;
  @media (min-width: 1024px) {
    display: block;
  }
`
const Title = styled.p`
  margin-top: 17px;
  margin-bottom: 5px;
  font-weight: bold;
  @media (min-width: 1024px) {
    margin-top: 20px;
  }
`

const OpeningTimes = styled.div`
  display: flex;
  justify-content: space-between;
  width: 285px;
  @media (min-width: 1024px) {
    width: 310px;
  }
`

const Col = styled.div`
  display: flex;
  flex-direction: column;
`
