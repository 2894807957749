import React, { useState } from 'react'
import Layout from '../components/Layout'
import CallBackBanner from '../components/CallBackBanner'
import GeneralEnquiryForm from '../components/shared/Form/GeneralEnquiryForm'
import GeneralHeader from '../components/Header/GeneralHeader'
import ImageAndTextContactUs from '../components/ImageAndTextColumns/ImageAndTextContactUs'
import imgTable from '../images/contact.svg'
import RequestCallBackModal from '../components/shared/Modal/RequestCallBackModal'

const breadcrumbData = [
  {
    title: 'Home',
    url: '/',
  },
  {
    title: 'Contact us',
    url: '/contact-us',
  },
]

const ContactUs = () => {
  const [showCallBackModal, toggleCallBackModal] = useState(false)
  return (
    <Layout
      activeLink="/contact-us"
      title="R&D Contact us"
      description="R&D Contact us"
    >
      <RequestCallBackModal
        showModal={showCallBackModal}
        toggleModal={() => toggleCallBackModal(false)}
        type={'Request a call back'}
        body={'Inactive account'}
      />
      <GeneralHeader breadcrumbData={breadcrumbData} heading="Contact us" />
      <ImageAndTextContactUs
        tag="GET IN TOUCH"
        heading="Speak to our expert team today"
        img={imgTable}
        imgAlt="People in a meeting"
      />
      <GeneralEnquiryForm
        noBorderTop
        formHeading="Alternatively, leave us a few details and we’ll be in touch"
        formBody={
          <p>
            {' '}
            If you would like to give us some background details here before{' '}
            <a onClick={() => toggleCallBackModal(true)}>
              requesting a call back
            </a>
            , please do, and of our team will contact you as soon as possible.
          </p>
        }
      />
      <CallBackBanner />
    </Layout>
  )
}

export default ContactUs
